import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import { ManageAgencyForm } from './ManageAgencyForm';
import { styles } from './styles';

import { createAgency } from '../../api/agencies';
import { Agency } from '../../api/agencies/types';
import { queryKeys } from '../../api/queryKeys';
import { NotificationMessage, useNotification } from '../../notifications';
import { theme } from '../../theme';
import { Button } from '../button';
import { LoadingOverlay } from '../loading-overlay';

export type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const CreateAgencyForm: React.FC<Props> = ({ open, setOpen }) => {
  const [agency, setAgency] = useState({
    buyers: [{ dsp: '', seat: '' }],
    advertisers: [''],
  } as unknown as Agency);
  const hasIncompleteAgency =
    !agency.name ||
    !agency.salesForceId ||
    agency?.buyers?.some((buyer) => !buyer.bidderId || !buyer.seat);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendNotification = useNotification();
  const cache = useQueryClient();
  const onCreateAgency = async () => {
    setIsSubmitting(true);
    try {
      await createAgency({
        ...agency,
        buyers: agency.buyers?.filter((buyer) => buyer.dsp || buyer.seat),
        advertisers: agency.advertisers?.filter((advertiser) => advertiser),
      });
      await cache.invalidateQueries([queryKeys.getAgencies]);

      sendNotification({
        type: 'success',
        message: <NotificationMessage id={'agency.form.createAgency.success'} />,
      });

      setOpen(!open);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isSubmitting && (
        <Backdrop
          open={true}
          style={{
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <LoadingOverlay />
        </Backdrop>
      )}
      <Typography variant="h2" sx={styles.title}>
        <FormattedMessage id="agency.form.create.title" />
      </Typography>
      <ManageAgencyForm agency={agency} setAgency={setAgency} />
      <Button
        labelId="agency.form.button.createAgency"
        endIconName="arrow-right"
        disabled={hasIncompleteAgency}
        onClick={onCreateAgency}
      />
    </>
  );
};

export { CreateAgencyForm };
