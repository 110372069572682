import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, TextField, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import { styles } from './styles';
import { formatDate } from './util';

import { addComment } from '../../api/deals';
import { Comment, Deal } from '../../api/deals/types';
import { User } from '../../api/users/types';
import { getFullName } from '../../features/users/utils';
import { NotificationMessage, useNotification } from '../../notifications';
import { theme } from '../../theme';
import { useUser } from '../../utils/keycloak/KeycloakProvider';
import { Button } from '../button';
import { LoadingOverlay } from '../loading-overlay';

export type Props = {
  comments: Deal['comments'];
  dealId: string;
};

export const DealComments: React.FC<Props> = ({ comments, dealId }) => {
  const intl = useIntl();
  const [newComment, setNewComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendNotification = useNotification();
  const user = useUser() as User;
  const onAddComment = async (comment: Comment) => {
    try {
      setIsSubmitting(true);
      await addComment(dealId, comment);
    } catch (e) {
      sendNotification({
        type: 'error',
        message: <NotificationMessage id="deal.detail.comments.error" />,
      });
      console.error('Could not add comment', comment, e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isSubmitting && (
        <Backdrop
          open={true}
          style={{
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <LoadingOverlay />
        </Backdrop>
      )}
      <Typography variant="h2" sx={styles.title}>
        <FormattedMessage id="deal.detail.comments.title" />
      </Typography>
      {comments?.map((comment, idx) => (
        <Box key={idx} mt={3} mb={3}>
          <Typography>
            <FormattedMessage id="deal.detail.comments.writtenBy" />
            <b>
              {getFullName({
                firstName: comment.writtenBy.firstName,
                lastName: comment.writtenBy.lastName,
              })}
            </b>
            <FormattedMessage id="deal.detail.comments.on" />
            <b>{formatDate(new Date(comment.creationDate))}</b>
          </Typography>
          <Box sx={styles.background}>
            <Typography>{comment.message}</Typography>
          </Box>
        </Box>
      ))}
      <Box mt={3} mb={3}>
        <TextField
          fullWidth
          multiline
          value={newComment}
          placeholder={intl.formatMessage({ id: 'deal.detail.comments.input' })}
          onChange={(e) => setNewComment(e.target.value)}
        />
      </Box>
      <Button
        endIconName="arrow-right"
        labelId="deal.detail.comments.add"
        onClick={() => {
          if (newComment?.length > 0) {
            const comment = {
              writtenBy: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
              },
              creationDate: new Date().toISOString(),
              message: newComment,
            };
            onAddComment(comment).then(() => {
              (comments ?? []).push(comment);
              setNewComment('');
            });
          } else {
            sendNotification({
              type: 'error',
              message: <NotificationMessage id="deal.detail.comments.empty" />,
            });
          }
        }}
      />
    </>
  );
};
